import React, { useState, useEffect, useRef }  from 'react';
import { 
    StatefulTable, 
    Input, 
    Checkbox, 
    Box, 
    Button, 
    Form, 
    FormGroup, 
    InlineMessage,
    ProgressCircle,
    Link
} from '@bigcommerce/big-design';
import axios from 'axios';
import './product-styles.scss';

const ProductList = React.memo((
    {
        selectedItems, 
        productQty, 
        products, 
        selectedProductArray,
        setCheckedItems,
        handleSelected, 
        qtyItems, 
        setQtyItems,
        priceItems,
        setPriceItems, 
        productAltStock, 
        errorForm, 
        setErrorForm, 
        cart, 
        setCart,
        clients,
        storeDetails,
        passwordEnable
    }
    ) => {
    const [items, setItems] = useState(products);
    const [filteredData, setFilteredData] = useState([...selectedProductArray, ...items]);
    const [cartError, setCartError] = useState('');
    const [errorType, setErrorType] = useState('');
    const [cartId, setCartId] = useState('');
    //for progress bar
    const [loading, setLoading] = useState(false);
	  const searchInput = useRef(null);

    const serializeForm =  (form) => {
        var obj = {};
        var formData = new FormData(form);
        for (var key of formData.keys()) {
            obj[key] = formData.get(key);
        }
        return obj;
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            axios({
                method: 'post',
                url: '/cart',
                data: JSON.stringify(serializeForm(event.target))
            })
            .then((response) => {
                const cartResponse = response.data.cartResponse;
		console.log('cart resp prod list');
		console.log(cartResponse);
                if (cartResponse.status !== undefined) {
                    if (cartResponse.errors !== undefined) {
console.log('cartResponseError1');
                        setCartError(cartResponse.errors);
                    } else {
console.log('cartResponseError2');
                        setCartError(cartResponse.title);
                    }
                    
                    setErrorType("error")
			
                } else if (cartResponse.data !== undefined){
                    setCart(cartResponse.data);
                    setCheckedItems({});
                    setCartError('Items have been added Successfully!');
		document.getElementById("bd-input-1").value ="";
		document.getElementById("bd-input-1").focus();
                    setErrorType("success");
 setQtyItems({});
                }
                setLoading(false);
                setPriceItems({});
            })
            .catch( error => {
                setLoading(false);
                console.log(`Error: ${error}`)
            });
        }
    };

    const handleMessageClose = () => {
        setCartError('');
    }

    useEffect(() => {
        if(cart === '') {
            setCartId('');
        } else {
            setCartId(cart.id);
        }
    }, [cart]);

    useEffect(() => {
        setItems(products);
        const resultandArray = [...selectedProductArray, ...products];

        //remove duplicate entries of products result from an array
        const filterArray = resultandArray.filter((c, index) => {
            return resultandArray.indexOf(c) === index;
        });
        setFilteredData(filterArray);

    }, [products]);

    useEffect(() => {
        document.querySelectorAll("button").forEach(button => { 
            button.addEventListener('click', function (event) {
                // If the clicked element doesn't have the right selector, bail
                if (event.target.innerText !== 'Add to Order') {
                    // Don't follow the link
                    event.preventDefault();
                }
            }, false);
        });
    }, []);

    
    const handleQuantityChange = (event) => {
        const productArray = (event.target.id).split("_");
        const productSKU = productArray[1];
        let qty = event.target.value;

	   // Reset to 1 if the entered value is less than 1
    if (qty < 1) {
        qty = 1;
   }



        if (qty !== '') {
            qty = parseInt(qty);
            //set quantity array when there is a value
            setQtyItems({...qtyItems, [productSKU] :qty});
        } else {
            //remove value when there is no value
            const getQtyData = qtyItems;
            delete getQtyData[productSKU];
            setQtyItems(getQtyData);
        }

        const quantityMin = parseInt(event.target.min);
        const quantityMax = parseInt(event.target.max);
       
        if (qty === '') {
            setErrorForm({...errorForm, [`${productSKU}_qty`]: `Please fill out this field.`});
        } else if (qty < quantityMin) {
            setErrorForm({...errorForm, [`${productSKU}_qty`]: `Value must be greater than or equal to ${quantityMin}.`});
        } else if (quantityMax === 0 && !isNaN(quantityMax)) {
            setErrorForm({...errorForm, [`${productSKU}_qty`]: `This item is not available.`});
        } else if (qty > quantityMax && !isNaN(quantityMax)) {
            setErrorForm({...errorForm, [`${productSKU}_qty`]: `Value must be less than or equal to ${quantityMax}.`});
        } else {
            setErrorForm({...errorForm, [`${productSKU}_qty`]: ""});
        }

    }

    const handlePriceChange = (event) => {
        const price = event.target.value;
        const skuArray = (event.target.id).split("_");
        const sku = skuArray[1];

        if (price !== '') {
            //set price array when there is a value
            setPriceItems({...priceItems, [sku] :price});
        } else {
            //remove value when there is no value
            const getPriceData = priceItems;
            delete getPriceData[sku];
            setPriceItems(getPriceData);
        }

        if (price === '') {
            setErrorForm({...errorForm, [`${sku}_price`]: `Please fill out this field.`});
        } else if (price < 0) {
            setErrorForm({...errorForm, [`${sku}_price`]: `Price must be greater than 0.`});
        } else {
            setErrorForm({...errorForm, [`${sku}_price`]: ""});
        }
    }

    const columns = [
        {
            header: '',
            align: 'left',
            hash: 'selected',
            render: ({ id, sku }) => {
                let checkbox_name = `checkbox_${id}`;
                let checkbox_sku = `checkbox_${sku}`;
		  // Get the stock levels
            const stock = productAltStock[sku] !== undefined ? productAltStock[sku]['PW'] : 0;
            const stockAlt = productAltStock[sku] !== undefined ? productAltStock[sku]['SW'] : 0;
            const stockSD = productAltStock[sku] !== undefined ? productAltStock[sku]['SD'] : 0;

            // Disable checkbox if all stock levels are 0
            const isDisabled = stock === 0 && stockAlt === 0 && stockSD === 0;

                return <Checkbox 
                    id={checkbox_sku} 
                    name={checkbox_name} 
                    checked={selectedItems[sku]}
                    onChange={handleSelected}
		    disabled={isDisabled} // Disable logic added here
                />;
            },
        },
        { 
            header: 'SKU', 
            hash: 'sku', 
            render: ({ custom_url, sku }) => {
                const productUrl = `${storeDetails.secure_url}${custom_url.url}`;
                return <Link 
                    href={productUrl}      target="_blank"        
                    external>
                        { sku }
                </Link> 
            }
        },
        { 
            header: 'Image', 
            hash: 'image', 
            render: ({ images, name }) => {  
                if (images.length > 0) { 
                    //filter data to check for the default thumbnail
                    let profile_image = images.filter((image) => {
                        return image.is_thumbnail === true
                    });
                    
                    return profile_image.length > 0 ? <img src={profile_image[0].url_tiny} alt={name} /> : 'No Image'
                } else {
                    return 'No Image';
                }
            }
        },
        { 
            header: 'Name', 
            hash: 'name', 
            render: ({ name }) => name 
        },
        { 
            header: 'Price ($)', 
            hash: 'price', 
            render: ({ id, price, sku, variant_id }) => {
                const price_id = `price_${sku}`;
                let price_name = "";
                let updatedPrice = price;
                if (priceItems[sku]) {
                    updatedPrice = priceItems[sku];
                    price_name = `price_${id}_${variant_id}`;
                }
                return (
                    <>
                        {
                            passwordEnable === false ?
                            price : 
                            <>
                                <Input
                                    label=""
                                    type="number"
                                    name={price_name}
                                    defaultValue={updatedPrice}
                                    onBlur={handlePriceChange}
                                    id={price_id}
                                    min="0.00"
                                    step="0.01"
                                    error={errorForm[`${sku}_price`]}
                                    required
                                />
                                <span className="error">{errorForm[`${sku}_price`]}</span>
                            </>
                        }
                    </>
                ); 
            }
        },
        { 
            header: 'Stock', 
            hash: 'inventory_level', 
            render: ({ sku })  => {
                return productAltStock[sku] !== undefined ? productAltStock[sku]['PW'] : 0;
            }
        },
        { 
            header: 'Stock Alt', 
            hash: 'inventory_level', 
            render: ({ sku }) => { 
                return productAltStock[sku] !== undefined ? productAltStock[sku]['SW'] : 0;
            }
        },
        { 
            header: 'Stock SD', 
            hash: 'inventory_level', 
            render: ({ sku }) => {
                return productAltStock[sku] !== undefined ? productAltStock[sku]['SD'] : 0;
            }
        },
        { 
            header: 'Qty', 
            hash: 'inventory_level', 
            render: (
                { 
                    id, 
                    sku, 
                    inventory_level, 
                    inventory_tracking,
                    variant_id  
                }) => {
                let quantity_name = `quantity_${id}_${variant_id}`;
                let quantity_sku = `quantity_${sku}`;
                let cartQuantity = 0;
                const qtyValue = (productQty[sku]) ?? '1'; 
                
                if (cart !== '') {
                    const cartLineItems = cart.line_items.physical_items;
                    if (cartLineItems.length > 0) {
                        cartLineItems.forEach((item) => {
                            if (item.sku === sku) {
                                cartQuantity += item.quantity;
                            }
                        });
                    }
                }
                const maximum_quantity = (inventory_tracking !== 'none') ? (parseInt(inventory_level)-cartQuantity) : '';
                if (selectedItems[sku]) {
                    return (
                        <Box>
                            <Input
                                label=""
                                description=""
                                type="number"
				 min="1"
                                max={maximum_quantity}
                                name={quantity_name}
                                id={quantity_sku}
                                defaultValue={qtyValue}
                                onBlur={handleQuantityChange}
                                error={errorForm[`${sku}_qty`]}
				ref={searchInput}
                                required
                            />
                            <span className="error">{errorForm[`${sku}_qty`]}</span>
                        </Box>
                    );
                } else {
                    return (
                        <Box></Box>
                    );                }
            }
        }
    ];

   
    return (
        <Form onSubmit={handleSubmit} id="product-form">
             {
                Object.keys(selectedProductArray).length !== 0 &&
                <FormGroup id="add-to-cart-btn">
                    <Button
                        name="submit"
                        id="add-to-order"
                        disabled={loading}
                    >
                        Add to Order
                        { loading &&  <ProgressCircle size="xSmall"/> }
                    </Button>
                </FormGroup>
            }
            <input 
                value={cartId}
                type="hidden"
                name="cartId"
            />
            <input 
                value={clients[0].id}
                type="hidden"
                name="customerId"
            />
            
            {
                cartError !== '' 
                && 
                <InlineMessage 
                    type={errorType} 
                    messages={[{ text: cartError }]} 
                    marginVertical="medium" 
                    onClose={handleMessageClose}
                />
            }
            <FormGroup>
                <StatefulTable
                    id="product-table"
                    keyField="id"
                    columns={columns}
                    items={filteredData}
                    itemName="Products"
		    stickyHeader
                    pagination
                />
            </FormGroup>
        </Form>
    );
});

export default  ProductList;
